body {
  font-family: "Poppins", sans-serif !important;
  overflow-x: hidden;
}
html{
  overflow-x: hidden;
}
/* .custom-time-picker .react-time-picker__inputGroup {
  @apply !flex gap-3 items-center;
} */
@keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes marqueeRight {
  1000% {
    transform: translateX(100%);
  }

  0% {
    transform: translateX(-100%);
  }
}

.animate-marquee {
  animation: marquee 16s linear infinite;
}

.animate-marquee-right {
  animation: marqueeRight 16s linear infinite;
}

.get-hired-modal-phone-number-input .PhoneInputCountry {
  display: flex !important;
  transform: translateY(-1px);

}

.get-hired-modal-phone-number-input .PhoneInputCountryIconImg,
.get-hired-modal-phone-number-input .PhoneInputCountryIcon {
  @apply h-6 w-6 rounded-full object-cover;

}

.get-hired-modal-phone-number-input input {
  @apply !w-full bg-transparent !h-11;
  /* height: 48px; */
  /* border-top-left-radius: 50%; */
  /* border-top-right-radius: 50%; */
}

.get-hired-modal-phone-number-input input:focus,
.get-hired-modal-phone-number-input input:focus-visible {
  @apply outline-none
}


.webkit-scroll-enable ::-webkit-scrollbar {
  width: 0px;
}