.contact-sale {
  background-color: #111019;
}

.contact-sale .form-select {
  padding: 12px;
  border: 1.5px solid #bbb6c6;
}

.contact-sale .form-select:focus {
  box-shadow: none !important;
}

.contact-sale label {
  color: #6c6684 !important;
}

.contact-sale input,
.contact-sale textarea {
  color: rgba(0, 0, 0, 1) !important;
  resize: none;
  padding: 12px;
  border: 1.5px solid #bbb6c6;
}

.contact-sale input:focus,
.contact-sale textarea:focus {
  background-color: rgba(247, 247, 248, 1);
}

.contact-sale input::placeholder,
.contact-sale textarea::placeholder {
  color: rgba(0, 0, 0, 1) !important;
}

.contact-sale input[type="checkbox"] {
  transform: scale(0.8) !important;
}

.contact-us .form-select {
  background-color: rgba(247, 247, 248, 1);
}

.contact-us .form-select:focus {
  box-shadow: none !important;
}

.contact-us label {
  color: rgba(0, 0, 0, 1) !important;
}

.contact-us input,
.contact-us textarea {
  color: rgba(0, 0, 0, 1) !important;
  resize: none;
  background-color: rgba(247, 247, 248, 1);
}

.contact-us input:focus,
.contact-us textarea:focus {
  background-color: rgba(247, 247, 248, 1);
}

.contact-us input::placeholder,
.contact-us textarea::placeholder {
  color: rgba(0, 0, 0, 1) !important;
}
